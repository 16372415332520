<script context="module" lang="ts">
  export type Category = {
    name: string;
    id: number;
    active?: boolean;
  };
</script>

<script lang="ts">
  import 'swiper/css';
  import 'swiper/css/navigation';
  
  import type { SwiperOptions } from 'swiper/types';
  import { Swiper } from 'swiper';
  import { onMount } from 'svelte';
  
  import type { Link } from '~/components/field-utils';
  import { getStoresContext, type StoresKey } from '~/stores';
  import type { ManageAPI } from "~/manage-api/types";
  import { filterOffersByStream, reduceByAvailableStreams } from '~/util/offer';
  import { asLink, type ReverseRouter } from '~/reverse-router';
  
  export let title: string;
  export let link: Link;
  export let categories: Category[];
  export let request_store_key: StoresKey;

  const stores_context = getStoresContext(request_store_key);
  const {  store,  offers, T } = getStoresContext(request_store_key);
  const site = stores_context.site;
  const scdata = stores_context.scdata;
  const offersPicks = stores_context.offer_picks;

  const reverse_router = stores_context.reverse_router
  $: t = $T;
  // swiper element
  let swiper_el: HTMLElement | null;
  let swiper: Swiper;
  let storeOffers: undefined | ManageAPI.Offer[];
  $: if ($store && $offers && $offers.length) {
      storeOffers = $offers.filter((offer) => offer.store?.id == $store?.id);
    }

  // If the type ID field is empty in Liferay, the category shouldn't be displayed:
  $: categories = categories.filter(x => x.id !== 0 || (x.id === 0 && (x.name === "Kaikki" || x.name === "All")));

  let filtered_list: ManageAPI.Offer[] = [];
  $: picked_list = [...$offersPicks]; // Copy the list to avoid modifying the original list
  $: available_categories = reduceByAvailableStreams(categories, picked_list);
  $: active_category = available_categories?.find(category => category.active);
  
  $: if (picked_list && !$store) {
    if (!active_category || active_category.id === 0) {
      filtered_list = picked_list;
    } else {
      filtered_list = filterOffersByStream(picked_list, active_category.id);
    }
  } else if (storeOffers && storeOffers.length) {
    filtered_list = storeOffers;
  }

  onMount(() => {
    swiper_el = document.querySelector('.olifts .swiper');
    if (swiper_el) {

      // swiper parameters
      const swiperParams: SwiperOptions = {
        slidesPerView: 'auto',
        loop: true,
        watchSlidesProgress: true,
      };

      swiper =  new Swiper(swiper_el, swiperParams);
    }
  });

  function switchCategory(category: Category) {
    if (active_category) active_category.active = false;
    active_category = category;
    category.active = true;
  }

  function next() {
    swiper?.slideNext();
  }
  
</script>

{#if filtered_list && filtered_list.length}
<div class="olifts d-md-flex flex-md-row" class:background-color-lift={$store}>
  {#if picked_list && !$store}
    <div class="categories px-4 flex-shrink-0">
      <h2 class="mb-4">{title}</h2>
      {#each available_categories as category}
        <button class="stream-toggle" class:active={category === active_category} on:click={() => switchCategory(category)}>
          {category.name}
        </button>
      {/each}
      <a class="btn btn-primary mt-3 mb-5 mt-md-5 mb-md-3" href={link.href} target={link.target}>
        {link.text}
      </a>
    </div>
  {:else if $store && filtered_list}
    <div class="store-title">{t`stores.excitingOffers`}</div>
  {/if}
  <div class="swiper">
    <div class="swiper-wrapper">
      {#each filtered_list as item}
          <!-- svelte-ignore a11y-missing-attribute -->
          <a class="swiper-slide item" {...asLink($reverse_router?.routeOffer(item))}>
            <img src={item.images?.at(0)} alt="" />
            <div class="by mt-3">{item.store?.name || $site?.name}</div>
            <div class="desc">{item.title}</div>
          </a>
      {/each}
    </div>
    <div class="next" on:click={next} on:keypress={next} role="button" tabindex="0">
       <img src="/theme/ainoa2024/images/arrow-circle--right.svg" alt="Next" />
    </div>
	</div>
</div>
{/if}

<style lang="scss">
  .olifts {
    a {
      text-decoration: none;
      &:hover {
        background-color: unset;
      }
    }
  }
.store-title {
  font-size:28px;
  font-weight: 600;
  width: 70%;
}
  .stream-toggle {
    margin: 0 10px 10px 0;
    padding: 8px 24px;
    background-color: var(--color-brand-primary-lighten-6, #ddd);
    border: none;
    border-radius: 100px;
  }

  .stream-toggle.active {
    background-color: var(--color-brand-primary, #333);
    color: var(--color-action-primary-inverted);
  }
  .background-color-lift {
    background-color: var(--color-brand-primary-lighten-6);
    padding: 80px 48px;
  }
  .olifts {
    /* Swiper items size */
    --item-width: 93vw;
    /* Gap between items */
    --gap: min(1vw, 8px);
    /* Order number of the gap the next button is overlaying */
    --nxt-btn-gap: 1;
    position: relative;
  }

  .swiper {
    margin-left: calc(var(--container-py) * -1);
    margin-right: calc(var(--container-py) * -1);
  }

  .next {
    position: absolute;
    top: calc(var(--item-width) / 2);
    /* Count items and gaps left from the button */
    left: calc(
      var(--item-width) * var(--nxt-btn-gap) + 
      var(--gap) * (var(--nxt-btn-gap) - .5)
    );
    width: 84px;
    height: 84px;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  @media screen and (min-width: 768px) {
    .olifts {
      --item-width: clamp(350px, 40vw, 420px);
    }
    .swiper {
      margin-left: 0;
    }
    .categories {
      width: var(--item-width);
    }
    .stream-toggle {
      display: block;
    }
  }

  @media screen and (min-width: 1024px) {
    .olifts {
      --item-width: clamp(310px, 30vw, 420px);
      --nxt-btn-gap: 2;
    }
  }

  @media screen and (min-width: 1440px) {
    .olifts {
      --item-width: clamp(310px, 22vw, 420px);
      --nxt-btn-gap: 3;
    }
  }

  @media screen and (min-width: 1920px) {
    .swiper {
      margin-right: 0;
    }
  }
  
  .item {
    text-align: center;
    width: var(--item-width);
    margin-right: var(--gap);
  }
  
  .item img {
    aspect-ratio: 1;
    width: 100%;
    object-fit: cover;
  }
  
  .item .by {
    text-transform: uppercase;
  }
  
  .item .desc {
    font-weight: 600;
  }
  
  .item .desc:after {
    content: '\00203a';
    margin-left: 4px;
  }

  .item:hover {
    color: var(--body-color);
  }
  .item:hover .desc {
    color: var(--color-brand-primary);
  }
  a:hover{
    text-decoration: none;
  }
</style>
